@use "../../../../styles/variables.scss"as *;
@use "../../../../styles/mediaQuery.scss"as *;
@use "../../../../styles/utilities.scss"as *;

.gap {
  width: 100%;
  height: 25px;
}

.footer {
  &__container {
    display: flex;
    align-items: center;
  }

  &__date {
    display: inline;
  }
}