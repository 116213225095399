@use "../../../../styles/variables.scss"as *;
@use "../../../../styles/mediaQuery.scss"as *;
@use "../../../../styles/utilities.scss"as *;

.header {
  width: 100%;
}

.banner {
  display: flex;
  background-color: $bg-survey_insights__header;
  height: 140px;
  padding: 55px 40px 40px 25px;

  &>h1 {
    font-size: 32px;
    color: white;
  }
}

.link {
  text-decoration: none;
}

.project {
  &__container {
    padding: 15px 20px;
    padding-top: 30px;
  }
}

.survey {
  &__container {
    display: flex;
  }

  &__details {
    margin-bottom: 20px;
  }

  &__date {
    &_range {
      font-weight: bold;
      margin-bottom: 5px;
      min-width: 250px;
    }
  }
}

@media (max-width: $media__md) {
  .survey {
    &__container {
      flex-direction: column;
    }
  }
}