@use "../../../../styles/variables.scss"as *;
@use "../../../../styles/mediaQuery.scss"as *;
@use "../../../../styles/utilities.scss"as *;
@use "../../../modal/Modal.module.scss" as*;

.participation {
  display: grid;
  gap: 5px;

  &__large_text {
    font-size: x-large;
    font-weight: 600;
  }

  &__legend {
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
    gap: 10px;
    font-size: medium;

    &_bar {
      width: 35px;
      height: 20px;
      background-color: $color__light-grey;
    }

    &_text {
      color: $color__grey;
    }
  }

  &_data{
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}


.bar {
  &_green {
    background-color: $data-block__green;
  }

  &_blue {
    background-color: $data-block__blue;
  }

  &_default {
    background-color: $color__light-grey;
  }
}


@media (max-width: $media__lg) {

  .participation__legend {
    font-size: medium;
    gap: 5px;
  }

  .data__content {
    grid-template-columns: 1fr 1fr;

  }
}

@media (max-width: $media__md) {

  .participation__legend {
    font-size: medium;
    gap: 5px;
  }

  .data__content {
    grid-template-columns: 1fr;

  }
}
