﻿// Colors
$color__white: #fff;
$color__green: #090;
$color__grey: #666;
$color__light-grey: #E4E4E4;
$color__dark-grey: #333;
$color__blue: #327FAA;
$color__maroon:#9D2956;
$color__orange: #ED6E22;

// Links
$link-color: #0366d6;
$link-color_reset: $color__grey;
$link-color_update: $color__green;
$link-color_inprogress: lighten($color__grey, 30%);

// Tooltip
$tooltip-color: $color__dark-grey;

// Buttons
$btn-color__primary: $color__green;
$btn-color__primary--hover: #007c00;
$btn-color__secondary: $color__grey;
$btn-color__secondary--hover: #555;

// Navigation
$bg-color__top-nav: #333;
$border-color__top-nav: rgb(173, 173, 173);

$bg-color__side-nav: #EEE;
$bg-color__side-nav-hover: #CCC;
$bg-color__side-nav-active: #333;
$border-color__side-nav: #E3E3E3;
$scroll-color__thumb: #898D8F;


// Environment
$bg-color__dev: #ff1493;


// Survey Insights
$bg-survey_insights__header: $color__blue;

// Warnings
$block-color__default: #E4E4E4;
$block-color__default_dark: #aaaaaa;
$block-color__good: $color__green;
$block-color__good_light: #4db38340;
$block-color__warning: #FFAF19;
$block-color__warning_light: #ffae191e;
$block-color__danger: #EC5D5D;
$block-color__danger_light: #EC5D5D40;

// Charts
$data-block__blue: $color__blue;
$data-block__green: $color__green;