@use "../../../styles/variables.scss"as *;
@use "../../../styles/utilities.scss"as *;

.disabled{
  color: $link-color_inprogress !important;
  cursor: default !important;
}

.link {

  &__button {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    width: fit-content;
    padding: 5px;
  }

  &__tooltip>div>div {
    max-width: 400px;
    width: 100%;
    background-color: $tooltip-color;
    padding: 15px;
    text-align: left;
  }
}


.default {
  color: $link-color;
}