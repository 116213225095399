@use "../../styles/variables.scss"as *;
@use "../../styles/utilities.scss"as *;


.message {
  width: 100%;

  &__background {
    width: 100%;
    padding: 10px;
  }

  &__container {
    border-left: 5px solid;
    width: 100%;
    padding-left: 10px;
  }
}

.default {
  &__bg {
    background-color: $color__light-grey;
  }

  &__text {
    border-color: $color__dark-grey;
    color: $color__dark-grey;
  }
}