@use "../../styles/variables"as *;
@use "../../styles/mediaQuery.scss"as *;

$navigation__width_full:240px;
$navigation__width_min:65px;
$navigation__padding-width:15px;

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
  border-radius: 5px;
  height: 100px;
}

.layout {
  height: 100%;
  width: 100%;
  
  &__container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: $navigation__width_min auto;
    transition: all .2s ease;
    overflow-x: scroll;

    &.active {
      grid-template-columns: $navigation__width_full auto;
    }
  }

  &__side_nav {
    height: 100%;
    width: $navigation__width_min;
    position: fixed;
    top: 0;
    padding-top: 30px;
    transition: all .2s ease;
    z-index: 1;

    &.active {
      width: $navigation__width_full;
    }
  }

  &__content {
    width: 100%;
    min-width: $media__xs;
    justify-content: center;
    transition: all .2s ease;
    display: flex;
    justify-items: center;
    background-color: white;
    overflow-y: overlay;

    &_items {
      width: 100%;
    }
  }
}

@media (max-width: $media__sm) {
  .layout {
    &__container {
      height: 100%;
      display: grid;
      grid-template-columns: 0px auto;
      transition: all .2s ease;

      &.active {
        grid-template-columns: $navigation__width_full auto;
      }
    }

    &__side_nav {
      height: 100%;
      width: 0px;
      position: fixed;
      top: 0;
      padding-top: 30px;
      transition: all .2s ease;
      
      &.active {
        width: $navigation__width_full;
      }

      & div{
        border: none;
      }
    }
  }
}