@use "../../../styles/variables.scss"as *;
@use "../../../styles/mediaQuery.scss"as *;
@use "../../../styles/utilities.scss"as *;
@use "../../../styles/overrides.scss"as *;

$icon-size:30px;

.block {
  &__container {
    height: 150px;
    width: 100%;
    border-radius: 5px;
    border: solid 2px $block-color__default;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__warning {
    position: absolute;
    top: -(calc($icon-size/2));
    right: -(calc($icon-size/2));
    width: $icon-size;
    height: $icon-size;
    color: white;
    border-radius: 50%;
    border: 3px solid white;
    @include center-all;
  }

  &__data {
    width: 100%;
    height: 100%;
    padding: 10px;
    @include center-all;
  }

  &__label {
    width: 100%;
    height: 50px;
    background-color: $color__light-grey;
    @include center-all;
    text-align: center;
  }
}