@use "../../../styles/variables.scss"  as *;
@use "../../../styles/mediaQuery.scss" as *;
@use "../../../styles/utilities.scss"  as *;
@use "../../../styles/overrides.scss"  as *;
@use "../../modal/Modal.module.scss"   as *;

.copy_tooltip>div>div {
    background-color: $tooltip-color;
}

.graph {
    &__display {
        display: grid;
        grid-template-rows: 55px auto 34px;
    }

    &__wrapper {
        width: 100%;
        height: 500px;
        border: solid 2px $color__light-grey;
        border-radius: 5px;
        padding: 10px 15px;
        transition: all 0.15s ;
    }
   
    // Title
    &__header {
        text-align: left;
        font-weight: bold;
        display: flex;
        padding-bottom: 10px;
        height: fit-content;

        &_title{
            font-size: medium;
        }

        &_subTitle{
            font-size: x-small;
            border-radius: 15px;
            padding: 3px 10px ;
            text-align: center;
            width: fit-content;
        }

        // Copy, Visibility and Expand
        &_options {
            display: flex;
            gap: 10px;
            color: $color__light-grey;

            & > svg {
                cursor: pointer;

                &:hover {
                    color: $color__dark-grey;
                }
            }
        }
    }

    // Graph
    &__body {
        width: 100%;
        height: 100%;
        background-color: $color__light-grey;
    }

    &__container {
        height: 100%;
        width: 100%;
        overflow: visible;
    }

    &__footer{
        width: 100%;
        height: 100%;

    }
}