@use "../../../styles/variables"as *;
@use "../../../styles/mediaQuery.scss"as *;
@use "../../../styles/overrides.scss"as *;
@use "../../../styles/utilities.scss"as *;

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $scroll-color__thumb;
  border-radius: 5px;
  height: 100px;
}

.navigation {
  height: 100%;
  width: 100%;
  background-color: $bg-color__side-nav;
  border-right: 4px solid $border-color__side-nav;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav {
  &__user {
    width: 100%;
    height: 137px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
  }

  &__category {
    width: 100%;
    padding: 5px 20px;
  }

  &__item {
    width: 100%;

  }

  &__link {
    width: 100%;
    height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0px 20px;
    text-decoration: none;
    color: $color__dark-grey;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
      background-color: $bg-color__side-nav-hover;
      color: black;
    }

    &_active {
      background-color: $bg-color__side-nav-active;
      color: white;

      &:hover {
        background-color: $bg-color__side-nav-active;
        color: white;
      }
    }
  }
}

.dropdown_icon {
  position: relative;
  display: flex;
  gap: 7px;
  left: -7px;
  color: $color__dark-grey;
}

.border {
  border-bottom: 2px solid $border-color__side-nav;
  margin: 2px 0px;
}
