@use "../../../styles/variables.scss"as *;
@use "../../../styles/mediaQuery.scss"as *;
@use "../../../styles/utilities.scss"as *;

.dropdown {
    width: 275px;
    max-height: 70%;
    padding: 5px 15px;
    background-color: white;
    border: solid 2px $color__light-grey;
    color:$color__dark-grey;
    display: flex;
    align-items: center;

    &:focus, &:hover, &:active {
        background-color: white !important;
        color:$color__dark-grey !important;
    }

    &__title {
        width: 100%;
        text-align: left;
    }
}

.dropdown_menu {
    width: 275px;
    max-height: 400px;
    padding: 15px;
    overflow-y: scroll;
}