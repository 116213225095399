@use "../../styles/variables.scss"as *;
@use "../../styles/mediaQuery.scss"as *;
@use "../../styles/utilities.scss"as *;
@use "../../styles/overrides.scss"as *;

.button_close {
  background-color: transparent;
  border: none;
  color: $color__dark-grey;
  text-align: center;

  &:hover, &:active, &:focus {
    background-color: transparent;
    color: $color__dark-grey;
  }

}

.modal {
  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 20px;
  }
  
  &__title {
    display: flex;
    align-items: flex-end;
    gap: 10px;

    &_text {
      font-weight: bold;
      font-size: large;
      margin: 0;
    }
  }

  &__footer {
    width: 100%;
    height: 100%;
    text-align: left;
  }
}

.footer_message {
  display: flex;
  gap: 5px;
  font-weight: 700;
}

.chart {
  width: 100%;
  height: 500px;
  display: flex;
}
