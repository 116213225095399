@use "../../../styles/variables" as *;

.navbar-environment-label {
  position: absolute;
  height: 100%;
  width:100px;
  left: 0; 
  right: 0;
  margin-left: auto; 
  margin-right: auto;  
  text-align: center;
  background: $bg-color__dev;
  color: white;
  padding: 0px 15px;
}

.hamburger_icon {
  box-sizing: border-box;
  width: 65px;
  height: 100%;
  color: white;
  border-right: 1px solid $border-color__top-nav;
  padding:0px 10px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

header {
  width: 100%;
  position: sticky;
  top:0;
  z-index: 2;
  animation: fadeIn 1s;
}


.navbar {
  height: 30px;
  margin:0px;
  padding:0px 20px;
  padding-left:0px;
  background-color: $color__dark-grey;

  &__brand {
    display: flex;
    align-items: center;
    gap: 5px;
    margin:0px;
    padding:0px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    
    &:hover {
      color: white;
    }

    &:visited {
      color: white;
    }
  }

  &__logo {
    width: auto;
    height: 20px;
  }
}

