@use "../../../styles/variables.scss"as *;
@use "../../../styles/mediaQuery.scss"as *;
@use "../../../styles/utilities.scss"as *;
@use "../../../styles/warning.scss"as *;

.container__data {
  padding: 10px 10px;
}

@media (max-width: $media__md) {
  .container__data {
    padding: 10px 20px;
  }
}

.divider {
  width: 100%;
  border-bottom: solid medium $color__light-grey;
}