@use "../../../../styles/variables.scss"as *;
@use "../../../../styles/mediaQuery.scss"as *;
@use "../../../../styles/utilities.scss"as *;
@use "../../../../styles/overrides.scss"as *;

.graph_control {
  width: 100%;
  margin-bottom: 20px;
}

.dropdown_chart__control {
  display: flex;
  gap: 15px;
  flex-direction: column;
  height: 70%;
}

.dropdown_section__title {
  display: flex;
  cursor: pointer;
}

.dropdown_chart__checkbox {
  padding-left: 15px;
}

.graph_container {
  width: 100%;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.graph_footer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.chart__type {
  display: flex;
  gap: 10px;
  color: $color__light-grey;
  cursor: pointer;

  & svg:hover {
    color: $color__dark-grey;
  }
}

.icon__selected {
  color: $color__blue;
}

.no_data {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  font-size: large;
  font-weight: bold;
  color: $color__light-grey;
}

@media (max-width: $media__lg) {
  .graph_container {
    width: 100%;
    gap: 20px;
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: $media__md) {
  .data__header {
    display: block;
  }
}

