@use "./variables"as *;
/* Provide sufficient contrast against white background */

* {
  font-family: Arial, Helvetica, sans-serif;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
}

html,
body {
  height: 100%;
  width: 100%;
}

a {
  color: $link-color;
}

code {
  color: #e01a76;
}

#root {
  height: 100%;
}

// Buttons
.btn-primary {
  background-color: $btn-color__primary;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: $btn-color__primary--hover;
  }
}

.btn-secondary {
  background: $btn-color__secondary;

  &:hover,
  &:active,
  &:focus {
    background-color: $btn-color__secondary--hover;
  }
}

// Navbar
.navbar {
  padding: 5px;
}

// Forms

label {
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 15px;
}

// FONT AWESOME ICONS

button .svg-inline--fa {
  font-size: 14px;
  margin-right: 4px;
}

// Tooltip
.tooltip.show {
  opacity: 1;
}

// Box Shadow
.box-shadow {
  box-shadow: 2px 2px 2px  $color__light-grey;
}


