.user_dropdown {
  &:after {
    color: white;
  }
}

.signin {
  list-style-type: none;
  color: white;
  display: flex;
  align-items: center;
}